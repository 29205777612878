<template>

  <div>
    <!-- Welcome main-top section -->
    <v-row>
      <div class="main-sec">
        <div class="btn-to-bottom"></div>
        <div class="main-sec-text">
          <h1 class="main-sec-title">CONTACT US</h1>
          <!-- <h5 class="main-sec-subtitle">Here comes the business networking platform that you've been waiting for</h5>
            <v-btn class="main-sec-btn">Log in here</v-btn> -->
        </div>
      </div>
    </v-row>

    <v-row class="sec-3part-round">
      <v-col cols="3" class="sec-3part-item">
        <v-img src="@/assets/images/assetvec/img-call.png" class="sec-3part-ico-img"></v-img>
      </v-col>

      <v-col cols="3" class="sec-3part-item">
        <v-img src="@/assets/images/assetvec/img-calender.png" class="sec-3part-ico-img"></v-img>
      </v-col>

      <v-col cols="3" class="sec-3part-item">
        <v-img src="@/assets/images/assetvec/img-location.png" class="sec-3part-ico-img"></v-img>
      </v-col>
    </v-row>

    <v-row class="sec-2vpart">
      <v-col cols="5" class="sec-2vpart-start">
        <v-card>
          <v-card-title>
            <h1 class="font-weight-bolder">
              Contact form
            </h1>
          </v-card-title>
          <v-card-text>

            <v-form>
              <v-text-field v-model="firstname" type="name" outlined dense label="First name" placeholder="Kana"
                hide-details class="mb-3"></v-text-field>
              <v-text-field v-model="lastname" type="name" outlined dense label="Last name" placeholder="Emille"
                hide-details class="mb-3"></v-text-field>
              <v-text-field v-model="email" type="email" outlined dense label="Email adress"
                placeholder="john@example.com" hide-details class="mb-3"></v-text-field>
              <v-text-field v-model="country" type="text" outlined dense label="Country" placeholder="Cameroun"
                hide-details class="mb-3"></v-text-field>
              <v-text-field v-model="phone" type="numeric" outlined dense label="Phone number"
                placeholder="655 32 03 69" hide-details class="mb-3"></v-text-field>
              <v-textarea v-model="message" type="text" outlined dense label="Message"
                placeholder="Tape your message here" hide-details class="mb-3"></v-textarea>
              <row class="d-flex align-center justify-space-between flex-wrap">
                <v-btn color="primary" class="mt-6 col-md-5">
                  Send
                </v-btn>
                <!-- <v-btn color="secondary" class="mt-6 col-md-5">
                  Reset form
                </v-btn> -->
              </row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4" class="sec-2vpart-end">
        <v-card class="d-flex flex-row align-center justify-center mb-8">
          <div class="">
            <router-link to="/" class="text-decoration-none">
              <v-img :src="require('@/assets/images/logos/logo.svg')" width="15em" alt="logo" contain>
              </v-img>
            </router-link>
          </div>
        </v-card>
        <v-card class="d-flex flex-row align-center justify-center">
          <div class="">
            <router-link to="/" class="text-decoration-none">
              <v-img :src="require('@/assets/images/assetvec/rec-map.png')" width="27em" height="100%" alt="logo"
                contain>
              </v-img>
            </router-link>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <!-- Some releases app -->
    <some-releases></some-releases>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline, mdiCallMade } from '@mdi/js'
import { ref } from '@vue/composition-api'
import CardBlockImg from "@/views/components/CardBlockImg.vue"
import SocializeBlockComponent from '@/layouts/components/SocializeBlockComponent.vue'
import SomeReleases from './components/SomeReleases.vue'

export default {
  components: {
    CardBlockImg,
    SocializeBlockComponent,
    SomeReleases
  },
  setup() {
    const isPasswordVisible = ref(false)
    const password = ref('')
    const email = ref('')
    const lastname = ref('')
    const firstname = ref('')
    const message = ref('')
    const country = ref('')

    return {
      isPasswordVisible,
      email,
      password,
      lastname,
      firstname,
      message,
      country,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiCallMade
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/styles/main-style.scss';
@import '~@/styles/utils-style.scss';
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
